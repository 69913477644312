import * as React from "react"
import { Link } from "gatsby"
import Dar from "@mui/icons-material/DoubleArrow"
import Layout from "../components/layout"
import Seo from "../components/seo"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/client-support/",
    label: "Client Support",
  },
]

const ClientSupport = () => (
  <Layout>
    <Seo title="Client Support" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <section className="section-title-top grid--bg --for-it-ops-2">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h1>CLIENT SUPPORT</h1>
            <h4>PUTTING HUMANS BACK IN SECURITY</h4>
            <p className="content">
              With Vigilant you are never alone. Our team is working with you
              and for you every day.
            </p>
            <a
              href="https://login.vigilantnow.com/"
              className="nav-section--cta-btn"
              target="_blank"
              rel="noreferrer"
            >
              <div className="d-flex align-items-center">
                <span
                  style={{ marginLeft: "25px" }}
                  className="nav-section--cta-title pr-5"
                >
                  client support login
                </span>
                <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
              </div>
            </a>
          </div>
          <div className="section-col-2"></div>
        </div>
      </div>
    </section>
    <div>
      <section className="section-3-icons">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <ul className="section-3-icons--list">
                <li>
                  <ul className="icon-list">
                    <li className="icon-img">
                      <img
                        src={"../images/client-support/dAccess.png"}
                        quality={95}
                        formats={["AUTO", "WEBP"]}
                        alt="Direct Access"
                        className="img-fluid"
                      />
                    </li>
                    <li className="icon-hr"></li>
                    <li className="icon-title">Direct Access</li>
                  </ul>
                </li>
                <li>
                  <ul className="icon-list">
                    <li className="icon-img">
                      <img
                        src={"../images/client-support/aReviews.png"}
                        quality={95}
                        formats={["AUTO", "WEBP"]}
                        alt="Archtecture Reviews"
                        className="img-fluid"
                      />
                    </li>
                    <li className="icon-hr"></li>
                    <li className="icon-title">Archtecture Reviews</li>
                  </ul>
                </li>
                <li>
                  <ul className="icon-list">
                    <li className="icon-img">
                      <img
                        src={"../images/client-support/iResponse.png"}
                        quality={95}
                        formats={["AUTO", "WEBP"]}
                        alt="Incident Response"
                        className="img-fluid"
                      />
                    </li>
                    <li className="icon-hr"></li>
                    <li className="icon-title">Breach Response</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="section-col-2 text-center">
              <p>
                Client support is more than a virtual presence, it is a genuine
                team-based service-focused reality. We are an entirely US based
                team that provides direct access to our hunt team, threat
                analysis team and client service team. We execute regular
                architecture reviews and recommendations to ensure maximum
                defense with no visibility gaps. Our constant contact through
                the CyberDNA® Command and Control Center is elevated further by
                our weekly reports and quarterly Security Business Reviews. We
                are your full service security resource. Your leadership team,
                board members and security staff will always have up-to-date
                intel.
              </p>
              <h4 className=" mt-5 vigilant-blue">
                “We are your eyes and ears so you can sleep better at night and
                have peace of mind.”
              </h4>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default ClientSupport
